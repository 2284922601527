import React, { PropsWithChildren, useMemo } from "react";
import { Outlet, useParams } from "react-router-dom";

import useOrg, { RawOrgProvider } from "../../hooks/useOrg";
import NotFoundPage from "../global-furniture/NotFoundPage";

export function SiteDataRoute({ children }: PropsWithChildren<unknown>) {
  const { siteId } = useParams<"siteId">();
  const baseOrgData = useOrg();

  const currentSite = useMemo(
    () => baseOrgData.currentOrg.sites.find((site) => site.id === siteId),
    [baseOrgData.currentOrg.sites, siteId],
  );

  if (!siteId || !currentSite) return <NotFoundPage />;

  return <RawOrgProvider value={{ ...baseOrgData, currentSite }}>{children ?? <Outlet />}</RawOrgProvider>;
}

/** @deprecated No longer does anything really, but wraps the new way of doing this to match the old one, so no code changes are needed */
export default function useSiteData() {
  return useOrg().currentSite!;
}
