import React, { PropsWithChildren, useMemo } from "react";
import { Outlet, useParams } from "react-router-dom";

import { FormCategory } from "../../../__generated__/graphql";
import NotFoundPage from "../components/global-furniture/NotFoundPage";
import useOrg, { OrgOverrideProvider } from "./useOrg";

export function OrgDataRoute({ children }: PropsWithChildren<unknown>) {
  // Take the organisation ID from the URL if present, if not then take the query parameter, and if that's not present then use "null" which acts to take the user's organisation.
  const { organisationId } = useParams<"organisationId">();

  if (!organisationId) return <NotFoundPage />;

  return <OrgOverrideProvider organisationId={organisationId}>{children ?? <Outlet />}</OrgOverrideProvider>;
}

/** @deprecated No longer does anything really, but wraps the new way of doing this to match the old one, so no code changes are needed */
export default function useOrgData() {
  return useOrg().currentOrg;
}

export function useQuestions(category: FormCategory) {
  const { questions } = useOrg().currentOrg;
  return useMemo(() => questions.filter((question) => question.category === category), [questions, category]);
}
