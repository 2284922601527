import classNames from "classnames";
import React, { PropsWithChildren, RefObject, useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import Modal from "../../../../../shared/components/design-system/Modal";
import { Translatable, Translation } from "../../../../../shared/components/translation";
import useDeepEquality from "../../../../../shared/hooks/useDeepEquality";
import useGeneratedId from "../../../../../shared/hooks/useGeneratedId";
import "./index.scss";

export enum PopupAxisPosition {
  Before,
  MatchEnd,
  Centred,
  MatchStart,
  After,
}

export interface PopupPosition {
  x: PopupAxisPosition;
  y: PopupAxisPosition;
}

export interface PopupOptions {
  xMargin?: number;
  yMargin?: number;
  forceOnScreen?: boolean;
}

interface InternalPopupOptions extends PopupOptions {
  scrollable: boolean;
}

const DEFAULT_X_MARGIN = 16;
const DEFAULT_Y_MARGIN = 16;
const DEFAULT_POSITION = {
  x: PopupAxisPosition.Centred,
  y: PopupAxisPosition.Before,
};

interface ElementPosition {
  left: number;
  right: number;
  top: number;
  bottom: number;
}

export function usePopup<T extends HTMLElement>(
  source: RefObject<HTMLElement>,
  positionPriority: PopupPosition[],
  options: InternalPopupOptions,
) {
  const popup = useRef<T>(null);

  const pos = useDeepEquality(positionPriority);
  const opts = useDeepEquality(options);
  const [currentPosition, setCurrentPosition] = useState(pos[0] ?? DEFAULT_POSITION);

  const update = useCallback(() => {
    const sourceElement = source.current;
    if (!sourceElement) {
      // This should never happen, but it's not a big issue if it does,
      // so log a warning in case it's one of us looking at the page,
      // and otherwise just let the popup render wherever it is.
      // When it's shown it'll get repositioned anyway, just not quite as smoothly.
      console.warn(
        "Popups should not be created without a source element. If you need to dynamically mount and unmount the source element, it is recommended to apply the same logic to the popup that depends on it. That way, when the source element is created, the popup element will know about it and appear in the right place.",
      );
      return;
    }
    const sourcePos = sourceElement.getBoundingClientRect();
    const scrolledPos = { top: sourcePos.top, left: sourcePos.left, right: sourcePos.right, bottom: sourcePos.bottom };
    if (options.scrollable) {
      scrolledPos.top += window.scrollY;
      scrolledPos.bottom += window.scrollY;
      scrolledPos.left += window.scrollX;
      scrolledPos.right += window.scrollX;
    }
    for (const position of pos) {
      if (positionFitsOnScreen(position, scrolledPos, popup.current!, opts)) {
        applyPosition(position, scrolledPos, popup.current!, opts);
        setCurrentPosition(position);
        return;
      }
    }
    applyPosition(pos[0] ?? DEFAULT_POSITION, scrolledPos, popup.current!, opts);
    setCurrentPosition(pos[0] ?? DEFAULT_POSITION);
  }, [opts, pos, source, options.scrollable]);

  useEffect(() => {
    update();
    window.addEventListener("resize", update);
    return () => window.removeEventListener("resize", update);
  }, [update]);

  return { source, popup, update, currentPosition };
}

function applyPosition(pos: PopupPosition, sourcePos: ElementPosition, popup: HTMLElement, options?: PopupOptions) {
  popup.style.left =
    axisPosition(
      pos.x,
      sourcePos.left,
      sourcePos.right,
      popup.clientWidth,
      options?.xMargin ?? DEFAULT_X_MARGIN,
      window.innerWidth,
      options?.forceOnScreen ?? false,
    ) + "px";
  popup.style.top =
    axisPosition(
      pos.y,
      sourcePos.top,
      sourcePos.bottom,
      popup.clientHeight,
      options?.yMargin ?? DEFAULT_Y_MARGIN,
      window.innerHeight,
      options?.forceOnScreen ?? false,
    ) + "px";
}

function axisPosition(
  pos: PopupAxisPosition,
  start: number,
  end: number,
  popup: number,
  margin: number,
  window: number,
  forceOnScreen: boolean,
): number {
  if (forceOnScreen && !axisFitsOnScreen(pos, window, start, end, popup, margin)) {
    const unforcedPosition = axisPosition(pos, start, end, popup, margin, window, false);
    return unforcedPosition < 0 ? margin : window - margin - popup;
  }
  switch (pos) {
    case PopupAxisPosition.Before:
      return start - popup - margin;
    case PopupAxisPosition.MatchEnd:
      return end - popup;
    case PopupAxisPosition.Centred:
      return (start + end - popup) / 2;
    case PopupAxisPosition.MatchStart:
      return start;
    case PopupAxisPosition.After:
      return end + margin;
  }
}

function positionFitsOnScreen(
  pos: PopupPosition,
  sourcePos: ElementPosition,
  popup: HTMLElement,
  options?: PopupOptions,
) {
  return (
    axisFitsOnScreen(
      pos.x,
      window.innerWidth,
      sourcePos.left - window.scrollX,
      sourcePos.right,
      popup.clientWidth,
      options?.xMargin ?? DEFAULT_X_MARGIN,
    ) &&
    axisFitsOnScreen(
      pos.y,
      window.innerHeight,
      sourcePos.top,
      sourcePos.bottom,
      popup.clientHeight,
      options?.yMargin ?? DEFAULT_Y_MARGIN,
    )
  );
}

function axisFitsOnScreen(
  pos: PopupAxisPosition,
  window: number,
  start: number,
  end: number,
  popup: number,
  margin: number,
) {
  switch (pos) {
    case PopupAxisPosition.Before:
      return start >= popup + margin * 2;
    case PopupAxisPosition.MatchEnd:
      return end >= popup + margin;
    case PopupAxisPosition.Centred: {
      const centre = (start + end) / 2;
      const requiredSpace = popup / 2 + margin;
      return centre >= requiredSpace && window - centre > requiredSpace;
    }
    case PopupAxisPosition.MatchStart:
      return window - start > popup + margin;
    case PopupAxisPosition.After:
      return window - end > popup + margin * 2;
  }
}

function Popup({
  positions,
  options,
  children,
  source,
  shown,
  zIndex,
}: PropsWithChildren<{
  positions: PopupPosition[];
  options?: PopupOptions;
  source: RefObject<HTMLElement>;
  shown: boolean;
  zIndex?: number;
}>) {
  const tooltipContainer = findOrCreateElement("tooltip-container");

  const { popup, update, currentPosition } = usePopup<HTMLDivElement>(source, positions, {
    ...options,
    scrollable: true,
  });
  const className = classNames({
    "c-popup": true,
    "c-popup--hidden": !shown,
    "c-popup--x-after": currentPosition.x === PopupAxisPosition.After,
    "c-popup--x-before": currentPosition.x === PopupAxisPosition.Before,
    "c-popup--x-centred": currentPosition.x === PopupAxisPosition.Centred,
    "c-popup--x-match-end": currentPosition.x === PopupAxisPosition.MatchEnd,
    "c-popup--x-match-start": currentPosition.x === PopupAxisPosition.MatchStart,
    "c-popup--y-after": currentPosition.y === PopupAxisPosition.After,
    "c-popup--y-before": currentPosition.y === PopupAxisPosition.Before,
    "c-popup--y-centred": currentPosition.y === PopupAxisPosition.Centred,
    "c-popup--y-match-end": currentPosition.y === PopupAxisPosition.MatchEnd,
    "c-popup--y-match-start": currentPosition.y === PopupAxisPosition.MatchStart,
  });

  useEffect(() => {
    if (shown) update();
  }, [update, shown]);

  return createPortal(
    <div ref={popup} className={className} style={{ zIndex }}>
      {children}
    </div>,
    tooltipContainer,
  );
}

export function ModalPopup({
  positions,
  title,
  options,
  children,
  source,
  shown,
  onClose,
}: PropsWithChildren<{
  positions: PopupPosition[];
  title: Translatable;
  options?: PopupOptions;
  source: RefObject<HTMLElement>;
  shown: boolean;
  onClose: () => void;
}>) {
  const titleId = useGeneratedId();
  const { popup, update } = usePopup<HTMLDivElement>(source, positions, {
    forceOnScreen: true,
    ...options,
    scrollable: false,
  });

  const titleRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (!shown) return;
    update();
  }, [update, shown]);

  return (
    <Modal isOpen={shown} onClose={onClose} closeOnClickOutside labelledBy={titleId} labelRef={titleRef} isLeaf>
      <div ref={popup} className={`c-popup ${shown ? "" : "c-popup--hidden"}`}>
        <h1 className="is-sr-only" tabIndex={-1} id={titleId} ref={titleRef}>
          <Translation props={title} />
        </h1>
        <button className="close-modal-popup" onClick={onClose}>
          <Translation t="closeButtonLabel" />
        </button>
        {children}
      </div>
    </Modal>
  );
}

export default Popup;

function findOrCreateElement(id: string) {
  const existing = document.getElementById(id);
  if (existing) return existing;
  const created = document.createElement("div");
  created.id = id;
  document.body.append(created);
  return created;
}
