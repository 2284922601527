import React from "react";

import LoadingOverlay from "../design-system/LoadingOverlay";

export default function Loading() {
  return (
    <main className="auth-layout">
      <LoadingOverlay visible={true} />
    </main>
  );
}
