import mixpanel from "mixpanel-browser";
import React, {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import useOrg, { RawOrgProvider } from "../../dashboard/src/hooks/useOrg";
import { ContextPayload as OrgDataPayload } from "../../dashboard/src/hooks/useOrg/types";
import Dialog, { DialogProps } from "../components/design-system/Dialog";
import useOnParentClose from "../components/design-system/tabs/closable";
import useDeepEquality from "./useDeepEquality";

export type AlertResolver<ReturnType> = (value: ReturnType) => void;

export type PropsWithAlertResolver<ReturnType, PropsType> = PropsType & {
  resolve: AlertResolver<ReturnType>;
  cancel: () => void;
};

export type AlertComponent<ReturnType, PropsType> = FunctionComponent<PropsWithAlertResolver<ReturnType, PropsType>>;

export type AlertDialogProps = Omit<DialogProps, "onClose" | "id" | "isOpen">;

/** You don't really need this, you can work around it by defining PropsType to not have the resolve prop, but TypeScript's type inference doesn't do that automatically, so including it here just makes life much easier. */
type OuterPropsType<PropsType> = Omit<PropsType, "resolve" | "cancel">;

/** Defines a dialog box to be displayed */
export interface AsyncDialog<ReturnType, PropsType> {
  /** The React component that forms the body of the modal */
  component: AlertComponent<ReturnType, PropsType>;
  /** The props to be passed to the React component that forms the body of the modal */
  props: OuterPropsType<PropsType>;
  /** The props to be passed to the dialog box component itself, eg, its width */
  dialogProps: AlertDialogProps;
  /** If set, we will tell Mixpanel when users open and close the dialog using this name. */
  mixpanelName?: string;
  /** Since the modal will be mounted outside the org data provider, it won't have access to useful things like the list of questions in the current org. Therefore we need to grab that data when the modal is created, store it here, and pass it into a new org data provider created by the modal provider. It sounds like a bit of a faff but it solves the problem in just a few lines of code. */
  orgData: OrgDataPayload;
}

interface StackEntry<ReturnType, PropsType> extends AsyncDialog<ReturnType, PropsType> {
  resolve: AlertResolver<ReturnType>;
  cancel: () => void;
  key: string;
}

interface OngoingModal<ReturnType> {
  result: Promise<ReturnType | null>;
  cancel: () => void;
}

const Context = createContext<
  <ReturnType, PropsType>(dialog: AsyncDialog<ReturnType, PropsType>) => OngoingModal<ReturnType>
>(() => {
  throw new Error("Do not use useAlert without a provider.");
});

export function AlertProvider({ children }: PropsWithChildren<unknown>) {
  // This "any" is needed because "unknown" is a stricter condition on an array we intend to maniplate
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [stack, setStack] = useState<Array<StackEntry<any, any>>>([]);

  // Using the "function" syntax here because the lambda syntax is interpreted as JSX (and we don't need to use the 'this' keyword anyway so who cares)
  const openDialog = useCallback(function updateStack<ReturnType, PropsType>(
    dialog: AsyncDialog<ReturnType, PropsType>,
  ): OngoingModal<ReturnType> {
    if (dialog.mixpanelName) mixpanel.track(`Opened the '${dialog.mixpanelName}' modal`);
    let cancel: () => void;
    const result = new Promise<ReturnType | null>((resolve) => {
      cancel = () => {
        if (dialog.mixpanelName) mixpanel.track(`Cancelled the '${dialog.mixpanelName}' modal`);
        resolve(null);
        setStack((stack) => stack.filter((entry) => entry !== newEntry));
      };
      const newEntry: StackEntry<ReturnType, PropsType> = {
        resolve(value: ReturnType) {
          if (dialog.mixpanelName) mixpanel.track(`Closed the '${dialog.mixpanelName}' modal`);
          resolve(value);
          setStack((stack) => stack.filter((entry) => entry !== newEntry));
        },
        cancel,
        key: Date.now().toString(36),
        ...dialog,
      };
      setStack((stack) => [...stack, newEntry]);
    });
    return { result, cancel: () => cancel() };
  },
  []);

  return (
    <Context.Provider value={openDialog}>
      {children}
      {stack.map((entry) => (
        <Dialog key={entry.key} isOpen {...entry.dialogProps} onClose={entry.cancel}>
          <RawOrgProvider value={entry.orgData}>
            <entry.component {...entry.props} resolve={entry.resolve} cancel={entry.cancel} />
          </RawOrgProvider>
        </Dialog>
      ))}
    </Context.Provider>
  );
}

/** Returns a function that displays a modal, and returns a promise which resolves when the modal closes. The modal can optionally return a value. (Modals returning errors is not currently supported.) */
export default function useModal<ReturnType, PropsType>(
  component: AlertComponent<ReturnType, PropsType>,
  dialogProperties: AlertDialogProps = {},
) {
  const setAlertBox = useContext(Context);
  const dialogProps = useDeepEquality(dialogProperties);
  const orgData = useOrg();

  const cancelRef = useRef<(() => void) | null>(null);
  useOnParentClose(() => cancelRef.current?.());

  // Also close the modal if the component that called this hook unmounts:
  useEffect(() => () => void cancelRef.current?.(), [cancelRef]);

  return useCallback(
    (props: OuterPropsType<PropsType>, mixpanelName?: string) => {
      const { result, cancel } = setAlertBox({ component, props, dialogProps, mixpanelName, orgData });
      cancelRef.current = cancel;
      return result;
    },
    [component, dialogProps, setAlertBox, orgData],
  );
}
