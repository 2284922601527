import * as Sentry from "@sentry/browser";
import { excludeGraphQLFetch } from "apollo-link-sentry";
import Amplify, { Auth } from "aws-amplify";
import mixpanel from "mixpanel-browser";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { FlagsProvider } from "react-unleash-flags";

import { RemoveReason, ToastData, ToastProvider } from "./../../shared/components/design-system/Toaster/context";
import AppRouter from "./App";
import ApolloProvider from "./components/global-furniture/ApolloProvider";
import ErrorReporting from "./components/global-furniture/ErrorReporting";
import "./styles/styles.scss";

const flagConfig = {
  appName: import.meta.env.REACT_APP_GITLAB_ENVIRONMENT ?? "development",
  host: "https://gitlab.com/api/v4/feature_flags/unleash/12602311",
  instanceId: "tz5ma-BHLsf1Yu5-r7QY",
};

export default function main() {
  Amplify.configure({
    aws_cognito_region: import.meta.env.REACT_APP_COGNITO_REGION,
    aws_project_region: import.meta.env.REACT_APP_COGNITO_REGION,
    aws_user_pools_id: import.meta.env.REACT_APP_COGNITO_USER_POOL_ID,
    aws_user_pools_web_client_id: import.meta.env.REACT_APP_COGNITO_CLIENT_ID,
  });
  Auth.configure({
    authenticationFlowType: import.meta.env.REACT_APP_COGNITO_LOCAL ? "USER_PASSWORD_AUTH" : "USER_SRP_AUTH",
    endpoint: import.meta.env.REACT_APP_COGNITO_LOCAL,
    oauth: {
      domain: import.meta.env.REACT_APP_COGNITO_DOMAIN,
      redirectSignIn: import.meta.env.REACT_APP_DOMAIN,
      redirectSignOut: import.meta.env.REACT_APP_DOMAIN,
      responseType: "code",
    },
  });

  if (import.meta.env.REACT_APP_SENTRY_ENVIRONMENT) {
    Sentry.init({
      dsn: "https://fc5e2b9260464613a18f56f2105b897d@o392151.ingest.sentry.io/5239314",
      beforeBreadcrumb: excludeGraphQLFetch,
      ignoreErrors: ["No current user — logging out", "Refresh Token has expired"],
      release: import.meta.env.REACT_APP_SENTRY_RELEASE!,
      environment: import.meta.env.REACT_APP_SENTRY_ENVIRONMENT!,
    });
  }

  mixpanel.init(import.meta.env.REACT_APP_MIXPANEL_TOKEN, {
    opt_out_tracking_by_default: true,
    secure_cookie: true,
    api_host: "https://api-eu.mixpanel.com",
    // Uncomment this locally to enable Mixpanel debugging. Do not commit to source control with this line present as it creates large amounts of noise in the browser console and makes development more difficult:
    // debug: import.meta.env.REACT_APP_SENTRY_ENVIRONMENT !== "prod",
  });

  createRoot(document.getElementById("root")!).render(
    <ErrorReporting>
      <BrowserRouter basename={new URL(import.meta.env.REACT_APP_DOMAIN as string).pathname}>
        <FlagsProvider config={flagConfig}>
          <ToastProvider onDismiss={onDismissToast}>
            <ApolloProvider>
              <AppRouter />
            </ApolloProvider>
          </ToastProvider>
        </FlagsProvider>
      </BrowserRouter>
    </ErrorReporting>,
  );
}

function onDismissToast(toast: ToastData, reason: RemoveReason) {
  if (reason === RemoveReason.TIMEOUT) return;
  mixpanel.track("Dismissed a toast", {
    reason: reason === RemoveReason.MANUAL ? "user" : "overflow",
    variant: toast.options.variant,
  });
}
